<template>
  <div class="userList">
     <el-row class="tac">

  <el-col :span="12">

    <el-menu
      default-active="1"
      class="el-menu-vertical-demo"
      background-color="#fff"
      text-color="#000"
      active-text-color="#ffd04b">

      <router-link to="/user/userInfo">
      <el-menu-item index="1">
        <i class="el-icon-menu"></i>
        <span slot="title">个人资料</span>
      </el-menu-item>
      </router-link>

      <router-link to="/user/userSet">
      <el-menu-item index="2">
        <i class="el-icon-edit"></i>
        <span slot="title">账号设置</span>
      </el-menu-item>
      </router-link>

      <router-link to="/user/schToken">
      <el-menu-item index="3" >
        <i class="el-icon-document"></i>
        <span slot="title">校园认证</span>
      </el-menu-item>
      </router-link>

      <router-link to="/user/userTrade">
      <el-menu-item index="4">
        <i class="el-icon-takeaway-box"></i>
        <span slot="title"><router-link to="/user/userTrade">二手信息</router-link></span>
      </el-menu-item>
      </router-link>

      <router-link to="/user/userQuestion">
      <el-menu-item index="5">
        <i class="el-icon-chat-dot-round"></i>
        <span slot="title"><router-link to="/user/userQuestion">交流问答</router-link></span>
      </el-menu-item>
       </router-link>

       <router-link to="/user/userCollect">
      <el-menu-item index="6">
        <!-- <el-icon><Star /></el-icon> -->
        <i class="el-icon-star-on"></i>
        <span slot="title"><router-link to="/user/userCollect">收藏</router-link></span>
      </el-menu-item>
       </router-link>

       <router-link to="/user/userFeedback">
      <el-menu-item index="7">
        <i class="el-icon-edit-outline"></i>
        <span slot="title"><router-link to="/user/userFeedback">问题反馈</router-link></span>
      </el-menu-item>
       </router-link>

       <router-link to="/user/MUser" v-if="isManager()">
       <el-menu-item index="8">
        <i class="el-icon-user-solid"></i>
        <span slot="title">用户管理</span>
      </el-menu-item>
       </router-link>
       <router-link to="/user/MSch" v-if="isManager()">
      <el-menu-item index="9">
        <i class="el-icon-notebook-2"></i>
        <span slot="title">公告管理</span>
      </el-menu-item>
       </router-link>

      <router-link to="/user/MTrade" v-if="isManager()">
       <el-menu-item index="10">
        <i class="el-icon-setting"></i>
        <span slot="title">二手信息管理</span>
      </el-menu-item>
       </router-link>

      <router-link to="/user/MQuestion" v-if="isManager()">
      <el-menu-item index="11">
        <i class="el-icon-setting"></i>
        <span slot="title">问答交流管理</span>
      </el-menu-item>
       </router-link>

       <router-link to="/user/MFeedback" v-if="isManager()">
      <el-menu-item index="12">
        <i class="el-icon-setting"></i>
        <span slot="title">反馈管理</span>
      </el-menu-item>
       </router-link>

          <router-link to="/user/MMuser" v-if="isMManager()">
      <el-menu-item index="13">
        <i class="el-icon-setting"></i>
        <span slot="title">管理员操作</span>
      </el-menu-item>
       </router-link>
    </el-menu>
  </el-col>
</el-row>
   </div>
</template>

<script>
export default {
  name: 'slider',
  data () {
    return {

    }
  },
  methods: {
    isManager () {
      if (this.uid === 0 || this.uid === 9) {
        // this.$router.go(0)
        return true
      } else {
        return false
      }
    },
    isMManager () {
      if (this.uid === 9) {
        // this.$router.go(0)
        return true
      } else {
        return false
      }
    }
  },
  computed: {
    // eslint-disable-next-line vue/no-dupe-keys
    uid: function () {
      return this.$store.state.userInfo.uid
    }
  }
}
</script>

<style lang="less" scoped>
li{
    list-style: none;
}
a{
    color: #000;
    text-decoration: none;
}
.userList{
    // width: 80%;
    // margin: 20px auto;
}
.el-col-12{
    width: 100%;
}
.el-menu-item, .el-submenu__title{
 font-size: .2167rem;
 transition: 0s;
 min-width: 100%;
}
.el-menu-item:hover{
    background-color: #F0F0F5 !important;
}
.el-menu-vertical-demo li{
  // padding-top: 1px;
}
</style>
