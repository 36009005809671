<template>
<div class="user">
  <Head></Head>
  <div class="main">
    <slider class="slider"></slider>
    <router-view class="slider-main">
        <router-view></router-view>
    </router-view>

  </div>

</div>
</template>

<script>
import { mapActions } from 'vuex'

import slider from '../components/Usersider.vue'
import Head from '../components/head.vue'
// import head from './head.vue'
export default {
  name: 'user',
  data () {
    return {

      userInfo: this.$store.state.userInfo
    }
  },
  methods: {
    ...mapActions(['initUserInfo'])

  },
  mounted () {
    this.initUserInfo()
  },
  components: {
    Head: Head,
    slider: slider

  }
}

</script>

<style lang="less" scoped>
html,body{
  background-color: #F5F6F7;
}
.user{

  background-color: #F5F6F7 !important;
  .main{
    display: flex;

    justify-content: space-around;
    width: 100%;
    margin: .1667rem auto;

  .slider{
    border-radius: .0667rem;
    flex: 1;
  }
  .slider-main{
    flex: 12;
    margin: 0 .0833rem;
    /deep/ td{
      text-align: center;
    }
     /deep/ th{
      text-align: center;
    }
  }
  }

}

</style>
